import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { persistConfig } from "../redux-persist-config";
import authReducer from "./reducers/AuthorizationSlice";
import phoneCallsReducer from "./reducers/PhoneCallsSlice";
import tokenRefreshMiddleware from "./middleware/TokenRefreshMiddleware";
import virtualNumberSettingsReducer from "./reducers/VirtualNumberSettingsSlice";
import leadsReducer from "./reducers/LeadsSlice"
import usersReducer from "./reducers/UsersSlice"

const rootReducer = combineReducers({
    authReducer,
    phoneCallsReducer,
    virtualNumberSettingsReducer,
    leadsReducer,
    usersReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER'],
      },
    }).concat(tokenRefreshMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
