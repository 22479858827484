import styled from "styled-components";
import { COLORS } from "../utils/constants";

const ButtonItem = styled.button`
  background-color: ${COLORS.grey};
  color: #333;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
`;

export default ButtonItem;
