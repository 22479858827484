import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

interface Leads {
  count: number | null;
  results: any;
  loading: boolean | null;
  error: any;
}

const initialState: Leads = {
  count: null,
  results: [],
  loading: null,
  error: null,
};

export const getLeads = createAsyncThunk(
  "leads/getLeads",
  async (page: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.authReducer.accessToken;

      const response = await axios.get("https://phone.altawest.ru/api/leads/", {
        params: {
          page: page,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error.response);
      throw error;
    }
  }
);

const LeadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLeads.fulfilled, (state, action: PayloadAction<Leads>) => {
        state.loading = false;
        state.error = null;
        state.count = action.payload.count;
        state.results = action.payload.results;
      })
      .addCase(getLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default LeadsSlice.reducer;
