import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useAppSelector } from "../hooks/redux";
import { useParams } from "react-router-dom";
import { CallInfo } from "../store/reducers/PhoneCallsSlice";
import styled from "styled-components";

function CallDetails() {
  const { results } = useAppSelector((state) => state.phoneCallsReducer);
  const { id } = useParams();
  const [selectedCall, setSelectedCall] = useState<CallInfo | null>(null);

  useEffect(() => {
    const foundCall = results.find((call) => call.id.toString() === id);
    if (foundCall) setSelectedCall(foundCall);
  }, [id, results]);

  return (
    <>
      <Header />
      <main>
        <h1>Телефонный звонок: {selectedCall?.pbx_call_id}</h1>
        <PhonesContainer>
          <p>
            Телефона позвонившего:{" "}
            <PhoneNumber>{selectedCall?.caller_number}</PhoneNumber>
          </p>
          <p>
            Телефон ответившего:{" "}
            <PhoneNumber>{selectedCall?.answer_number}</PhoneNumber>
          </p>
        </PhonesContainer>
        <h2>Записи</h2>
        {selectedCall &&
        selectedCall.call_recs &&
        selectedCall.call_recs.length > 0 ? (
          <CallRecordsContainer>
            {selectedCall.call_recs.map((call_rec, index) => (
              <CallRecords key={call_rec}>
                <audio controls>
                  <source src={call_rec} type="audio/mpeg" />
                  Браузер не поддерживает аудио элемент
                </audio>
                <p>Расшифровка</p>
              </CallRecords>
            ))}
          </CallRecordsContainer>
        ) : (
          <p>Нет доступных записей</p>
        )}
      </main>
    </>
  );
}

const PhonesContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const PhoneNumber = styled.span`
  font-weight: bold;
  font-size: 20px;
`;

const CallRecordsContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CallRecords = styled.div`
  align-items: center;
  display: flex;
  gap: 40px;
`;

export default CallDetails;
