import React from "react";

import styled from "styled-components";

import AuthForm from "../components/AuthForm";

function Authorization() {
  return (
    <main>
      <FormMain>
        <AuthorizationContainer>
          <AuthForm />
        </AuthorizationContainer>
      </FormMain>
    </main>
  );
}

const FormMain = styled.main`
  height: 100vh;
  width: 100%;
  margin: 0 auto;
`;

const AuthorizationContainer = styled.div`
  width: 50%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default Authorization;
