import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";


export interface VirtualNumberSettingsInterface {
    count: number | null;
    // results: {
    //     name: string | null;
    //     virtualNumber: string | null;
    //     region: number | null;
    //     isIntegrated: boolean | null;
    //     site: string | null;
    //     group: string | null;
    //     department: string | null;
    //     stock: string | null;
    //     organization: string | null;
    //     responsible: string | null
    // }[]
    results: any;
    loading: boolean;
    error: any;
}

const initialState: VirtualNumberSettingsInterface = {
    count: null,
    results: [],

    loading: false,
    error: null,
};

export const getVirtualNumberConfig = createAsyncThunk(
  "virtualNumber/getVitrualNumber",
  async (page: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.authReducer.accessToken;
      const response = await axios.get(
        "https://phone.altawest.ru/api/virtual-number-configs/",
        {
          params: {
            page: page,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error.response);
      throw error;
    }
  }
);

const reportsSlice = createSlice({
  name: "virtualNumber",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVirtualNumberConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVirtualNumberConfig.fulfilled, (state, action: PayloadAction<any>) => {
        state.count = action.payload.count
        state.results = action.payload.results
        state.loading = false;
        state.error = null;
      })
      .addCase(getVirtualNumberConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default reportsSlice.reducer;
