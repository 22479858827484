import React, { useEffect, useState } from "react";

import Header from "../components/Header";

import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { getUsers } from "../store/reducers/UsersSlice";

interface UsersTableData {
  name: string | null;
  login: string | null;
  ms_id: string | null;
  id: number | null;
}

const columns: ColumnsType<UsersTableData> = [
  {
    title: "Имя",
    width: 100,
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Логин",
    width: 100,
    dataIndex: "login",
    key: "login",
  },
  {
    title: "ms id",
    dataIndex: "ms_id",
    key: "ms_id",
    width: 150,
  },
  {
    title: "id",
    width: 100,
    dataIndex: "id",
    key: "id",
  },
];

function Users() {
  const dispatch = useAppDispatch();
  const [usersData, setUsersData] = useState<UsersTableData[]>([]);
  const { results } = useAppSelector((state) => state.usersReducer);

  useEffect(() => {
    const fetchUsers = () => {
      dispatch(getUsers("1"));
    };

    fetchUsers();
  }, [dispatch]);

  useEffect(() => {
    if (results) {
      const formattedData = results.map((item) => ({
        key: item.id,
        name: item.name,
        login: item.login,
        ms_id: item.ms_id,
        id: item.id,
      }));
      setUsersData(formattedData);
    }
  }, [results]);

  return (
    <>
      <Header />
      <main>
        <h1>Пользователи</h1>
        <Table
          pagination={false}
          dataSource={usersData}
          columns={columns}
        ></Table>
      </main>
    </>
  );
}

export default Users;
