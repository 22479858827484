import React from "react";

import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/redux";

interface PrivateElementProps {
  children: React.ReactNode;
}

const PrivateElement: React.FC<PrivateElementProps> = ({ children }) => {
  const { accessToken } = useAppSelector((state) => state.authReducer);

  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default PrivateElement;
