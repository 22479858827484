// import { AnyAction, Dispatch, Middleware } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { updateToken } from "../reducers/AuthorizationSlice";

const tokenRefreshMiddleware: any =
  (store: { getState: () => RootState; dispatch: any }) =>
  (next: any) =>
  (action: any) => {
    if (
      action.type.endsWith("rejected") &&
      action.error?.message === "Request failed with status code 401"
    ) {
      const refreshToken = store.getState().authReducer.refreshToken;
      if (refreshToken) {
        store.dispatch(updateToken(refreshToken));
      }
    }
    return next(action);
  };

export default tokenRefreshMiddleware;
