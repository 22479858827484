import React, { useCallback, useEffect, useState } from "react";

import Header from "../components/Header";

import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { CallInfo, getPhoneCalls } from "../store/reducers/PhoneCallsSlice";
import { Link } from "react-router-dom";
import formatDateToRussian from "../utils/formatDateToRussia";

export interface Pagination {
  current: number;
  pageSize: number;
}

const columns: ColumnsType<CallInfo> = [
  {
    title: "ID звонка",
    width: 100,
    dataIndex: "pbx_call_id",
    key: "pbx_call_id",
    render: (pbx_call_id, record) => (
      <Link to={record.id.toString()}>{pbx_call_id}</Link>
    ),
  },
  {
    title: "Исходящий",
    width: 100,
    dataIndex: "is_out",
    key: "is_out",
    sorter: (a, b) => (a.is_out === b.is_out ? 0 : a.is_out ? -1 : 1),
    render: (is_out: boolean) => (is_out ? "Исходящий" : "Входящий"),
    onCell: (cell) => {
      return {
        style: {
          backgroundColor: cell.is_out ? "#f2fabb" : "#bbfad9",
          textAlign: "center",
        },
      };
    },
  },
  {
    title: "Кто позвонил",
    dataIndex: "caller_number",
    key: "caller_number",
    width: 150,
  },
  {
    title: "Кто ответил",
    width: 100,
    dataIndex: "answer_number",
    key: "answer_number",
  },
  {
    title: "Ответственный",
    dataIndex: "responsible",
    key: "responsible",
    width: 150,
  },
  {
    title: "Дата создания",
    dataIndex: "created_at",
    key: "created_at",
    width: 150,
    render: (created_at) => formatDateToRussian(created_at),

    sorter: (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  },
];

function PhoneCalls() {
  const dispatch = useAppDispatch();
  const [phoneCallData, setPhoneCallData] = useState<CallInfo[]>([]);
  const { results, count } = useAppSelector((state) => state.phoneCallsReducer);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 100,
    total: 0,
    position: ["topLeft"],
  });

  const fetchPhoneCalls = useCallback(
    (page = pagination.current) => {
      if (page) {
        dispatch(getPhoneCalls(page.toString()));
      }
    },
    [dispatch, pagination]
  );

  useEffect(() => {
    fetchPhoneCalls();
    const interval = setInterval(() => fetchPhoneCalls(), 10000);
    return () => clearInterval(interval);
  }, [fetchPhoneCalls]);

  useEffect(() => {
    if (results) {
      const formattedData = results.map((item) => ({
        ...item,
        key: item.pbx_call_id,
      }));
      setPhoneCallData(formattedData);
    }
  }, [results]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: count ?? 1,
    }));
  }, [count]);

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    const { current, pageSize } = newPagination;
    if (current !== undefined) {
      fetchPhoneCalls(current);
      setPagination({ ...newPagination, pageSize: pageSize || 100 });
    }
  };

  return (
    <>
      <Header />
      <main>
        <h1>Звонки</h1>
        <Table
          dataSource={phoneCallData}
          columns={columns}
          pagination={pagination}
          onChange={handleTableChange}
        ></Table>
      </main>
    </>
  );
}

export default PhoneCalls;
