import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

interface MSUser {
  id: number;
  login: string;
  ms_id: string;
  name: string;
}

export interface CallInfo {
  created_at: string | number | Date;
  pbx_call_id: string | null;
  is_out: boolean | null;
  caller_number: string | null;
  answer_number: string | null;
  name: string | null;
  login: string | null;
  call_recs: string[];
  responsible: string;
  ms_user: MSUser;
  id: number;
}

interface PhoneCall {
  count: number | null;
  next: null | string;
  previous: null | string;
  results: CallInfo[];
  loading: boolean | null;
  error: any;
}

const initialState: PhoneCall = {
  count: null,
  next: null,
  previous: null,
  results: [],
  loading: null,
  error: null,
};

export const getPhoneCalls = createAsyncThunk(
  "phoneCalls/getPhoneCalls",
  async (page: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.authReducer.accessToken;
      const response = await axios.get(
        "https://phone.altawest.ru/api/phone-calls/",
        {
          params: {
            page: page,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error.response);
      throw error;
    }
  }
);

const phoneCallSlice = createSlice({
  name: "phoneCalls",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPhoneCalls.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPhoneCalls.fulfilled,
        (state, action: PayloadAction<PhoneCall>) => {
          state.count = action.payload.count;
          state.next = action.payload.next;
          state.previous = action.payload.previous;
          state.results = action.payload.results.map((callInfo) => {
            let name = "";
            let login = "";
            if (callInfo.ms_user) {
              name = callInfo.ms_user.name || " ";
              login = callInfo.ms_user.login || " ";
            }

            return {
              ...callInfo,
              responsible: login + " " + name,
            };
          });
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(getPhoneCalls.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default phoneCallSlice.reducer;
