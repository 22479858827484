import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";

import { COLORS } from "../utils/constants";
import Button from "./ButtonItem";

import { useAppDispatch } from "../hooks/redux";
import { postAuth } from "../store/reducers/AuthorizationSlice";

import { trackPromise } from "react-promise-tracker";

function AuthForm() {
  const [errMessage, setErrMessage] = useState(false);

  const navigate = useNavigate();
  const dispath = useAppDispatch();

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      onSubmit={async ({ username, password }) => {
        try {
          const resultAction = await trackPromise(
            dispath(postAuth({ username, password }))
          );
          unwrapResult(resultAction);
          navigate("/");
        } catch (err) {
          console.log(err);
          setErrMessage(true);
        }
      }}
    >
      {({ errors, touched }) => (
        <FormItem className="form">
          <FormHeading>Войти в аккаунт</FormHeading>
          <div>
            <InputField
              className={`form__field  ${
                errors.username && touched.username
                  ? "form__invalid-field"
                  : touched.username
                  ? "form__valid-field"
                  : ""
              }`}
              type="text"
              name="username"
              id="username"
              placeholder="Введите логин"
            ></InputField>

            <div>
              <InputField
                className={`form__field  ${
                  errors.password && touched.password
                    ? "form__invalid-field"
                    : touched.password
                    ? "form__valid-field"
                    : ""
                }`}
                type="password"
                name="password"
                id="password"
                placeholder="Введите пароль"
              ></InputField>
            </div>
          </div>
          {errMessage && <ErrorMessage>Неверный логин или пароль</ErrorMessage>}
          <ButtonContainer>
            <div>
              <Button
                style={{ backgroundColor: COLORS.primary }}
                type="submit"
                value="default"
              >
                Продолжить
              </Button>
            </div>
          </ButtonContainer>
        </FormItem>
      )}
    </Formik>
  );
}

const FormItem = styled(Form)`
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 28px;
  height: auto;
  padding: 32px;
  width: 80%;
`;

const InputField = styled(Field)`
  display: block;
  width: 100%;
  height: 50px;
  background-color: ${COLORS.grey};
  border: 1px solid rgba(128, 128, 128, 0.2);
  border-radius: 6px;
  box-sizing: border-box;
  padding-left: 16px;
  margin-bottom: 10px;
  font-size: 16px;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  padding-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormHeading = styled.h2`
  text-align: center;
`;

export default AuthForm;
