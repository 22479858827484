import React, { useEffect, useState } from "react";

import Header from "../components/Header";

import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { getVirtualNumberConfig } from "../store/reducers/VirtualNumberSettingsSlice";

export interface Pagination {
  current: number;
  pageSize: number;
}

interface VirtualNumberSettingsTableData {
  name: string | null;
  virtualNumber: string | null;
  region: number | null;
  isIntegrated: boolean | null;
  site: string | null;
  group: string | null;
  department: string | null;
  stock: string | null;
  organization: string | null;
  responsible: string | null;
}

const columns: ColumnsType<VirtualNumberSettingsTableData> = [
  {
    title: "Имя",
    width: 100,
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Виртуальный номер",
    width: 100,
    dataIndex: "virtualNumber",
    key: "virtualNumber",
  },
  {
    title: "Регион",
    dataIndex: "region",
    key: "region",
    width: 100,
  },
  {
    title: "Интегрировано ли",
    width: 100,
    dataIndex: "isIntegrated",
    key: "isIntegrated",
    render: (isIntegrated: boolean) =>
      isIntegrated ? "Интегрировано" : "Не интегрировано",
    onCell: (cell) => {
      return {
        style: {
          backgroundColor: cell.isIntegrated ? "#bbfad9" : "#f2fabb",
          textAlign: "center",
        },
      };
    },
  },
  {
    title: "Сайт",
    dataIndex: "site",
    key: "site",
    width: 150,
  },
  {
    title: "Группа",
    dataIndex: "group",
    key: "group",
    width: 100,
  },
  {
    title: "Отдел",
    dataIndex: "department",
    key: "department",
    width: 100,
  },
  {
    title: "Склад",
    dataIndex: "stock",
    key: "stock",
    width: 100,
  },
  {
    title: "Организация",
    dataIndex: "organization",
    key: "organization",
    width: 100,
  },
  {
    title: "Ответсвенный",
    dataIndex: "responsible",
    key: "responsible",
    width: 100,
  },
];

function VirtualNumberSettings() {
  const dispatch = useAppDispatch();
  const [virtualNumberData, setVirtualNumberData] = useState<
    VirtualNumberSettingsTableData[]
  >([]);
  const { results } = useAppSelector(
    (state) => state.virtualNumberSettingsReducer
  );

  useEffect(() => {
    const fetchVirtualNumberSettings = () => {
      dispatch(getVirtualNumberConfig("1"));
    };

    fetchVirtualNumberSettings();
  }, [dispatch]);

  useEffect(() => {
    if (results) {
      const formattedData = results.map(
        (item: {
          name: string;
          virtual_number: string;
          region: string;
          is_integrated: boolean;
          site: { name: string };
          group: { name: string };
          department: { name: string };
          stock: { name: string };
          organization: { name: string };
          responsible: { name: string };
        }) => ({
          key: item.virtual_number,
          name: item.name,
          virtualNumber: item.virtual_number,
          region: item.region,
          isIntegrated: item.is_integrated,
          site: item.site ? item.site.name : null,
          group: item.group ? item.group.name : null,
          department: item.department ? item.department.name : null,
          stock: item.stock ? item.stock.name : null,
          organization: item.organization ? item.organization.name : null,
          responsible: item.responsible ? item.responsible.name : null,
        })
      );
      setVirtualNumberData(formattedData);
    }
  }, [results]);

  return (
    <>
      <Header />
      <main>
        <h1>Настройка виртуальных номеров</h1>
        <Table dataSource={virtualNumberData} columns={columns}></Table>
      </main>
    </>
  );
}

export default VirtualNumberSettings;
