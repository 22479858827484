import React from "react";

import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";

import ButtonItem from "./ButtonItem";

import { COLORS } from "../utils/constants";
import { useDispatch } from "react-redux";

import { logoutUser } from "../store/reducers/AuthorizationSlice";

function Header() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <HeaderContainer>
      <section>
        <nav>
          <NavItem to="/">Главная</NavItem>
          <NavItem to="/admin">Админка</NavItem>
          <NavItem to="/phone-calls">Звонки</NavItem>
          <NavItem to="/reports">Отчеты</NavItem>
          <NavItem to="/leads">Лиды</NavItem>
          <NavItem to="/virtual-number-settings">
            Настройки виртуальных номеров
          </NavItem>
          <NavItem to="/users">Пользователи</NavItem>
        </nav>
        <ButtonItem
          onClick={() => {
            dispatch(logoutUser());
            navigate("/login");
          }}
        >
          Выйти
        </ButtonItem>
      </section>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header`
  height: 56px;
  background-color: ${COLORS.primary};
  width: 100%;
  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 100%;
  }

  nav {
    display: flex;
    align-items: center;
    gap: 20px;

    a {
      color: white;
    }
  }
`;

const NavItem = styled(NavLink)`
  color: white;
  text-decoration: none;
  padding: 8px 12px;

  &.active {
    text-decoration: underline;
  }
`;

export default Header;
