import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

interface MSUser {
  id: number;
  login: string;
  ms_id: string;
  name: string;
}

interface UsersInterface {
  count: number | null;
  results: MSUser[];
  loading: boolean | null;
  error: any;
}

const initialState: UsersInterface = {
  count: null,
  results: [],
  loading: null,
  error: null,
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (page: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const accessToken = state.authReducer.accessToken;
      const response = await axios.get(
        "https://phone.altawest.ru/api/ms-users/",
        {
          params: {
            page: page,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error.response);
      throw error;
    }
  }
);

const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getUsers.fulfilled,
        (state, action: PayloadAction<UsersInterface>) => {
          state.loading = false;
          state.error = null;
          state.count = action.payload.count;
          state.results = action.payload.results;
        }
      )
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default UsersSlice.reducer;
