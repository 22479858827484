import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

interface LoginForm {
  username: string;
  password: string;
}

interface UserCharacteristics {
  accessToken: string | null;
  refreshToken: string | null;
  username: null | string;
  loading: boolean;
  error: any;
}

interface TokenInfo {
  exp: number;
  iat: number;
  jti: string;
  token_type: string;
  user_id: number;
  username: string;
}

const initialState: UserCharacteristics = {
  accessToken: null,
  refreshToken: null,
  username: null,
  loading: false,
  error: null,
};

export const postAuth = createAsyncThunk(
  "auth/postAuth",
  async ({ username, password }: LoginForm) => {
    try {
      const response = await axios.post(
        "https://phone.altawest.ru/api/token/",
        { username, password },
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error.response);
      throw error;
    }
  }
);

export const updateToken = createAsyncThunk(
  "auth/updateToken",
  async (refresh: string) => {
    try {
      const response = await axios.post(
        "https://phone.altawest.ru/api/token/refresh/",
        { refresh },
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error.response);
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAuth.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;

        const user: TokenInfo = jwtDecode(action.payload.access);
        state.username = user.username;
      })
      .addCase(postAuth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateToken.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
      })
      .addCase(updateToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { logoutUser } = authSlice.actions;
export default authSlice.reducer;
