import React, { useCallback, useEffect, useState } from "react";

import Header from "../components/Header";

import { Table, Tag } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { getLeads } from "../store/reducers/LeadsSlice";
import formatDateToRussian from "../utils/formatDateToRussia";
import { trackPromise } from "react-promise-tracker";

interface LeadsTableData {
  id: number | null;
  name: string | null;
  statusName: string | null;
  money: number | null;
  statusColor: string | null;
  counterpartyPhone: string | null;
  createdAt: string | null;
  msUserName: string | null;
  fullCounterparty: string | null;
  fullMsUser: string | null;
}

//TODO изменить высоту строки

const columns: ColumnsType<LeadsTableData> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 100,
  },
  {
    title: "Имя",
    dataIndex: "name",
    key: "name",
    width: 100,
  },
  {
    title: "Статус",
    dataIndex: "statusName",
    key: "statusName",

    render: (text, record) => (
      <Tag color={record.statusColor ?? "inherit"}>{text}</Tag>
    ),
    width: 100,
  },
  {
    title: "Сумма",
    dataIndex: "money",
    key: "money",

    width: 100,
  },
  {
    title: "Контрагент (телефон)",
    dataIndex: "counterpartyPhone",
    key: "counterpartyPhone",
    render: (text, record) => (
      <div title={record.fullCounterparty ?? ""}>{text}</div>
    ),
    width: 150,
  },
  {
    title: "Дата",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 150,
    render: (createdAt) => formatDateToRussian(createdAt),
  },
  {
    title: "MS User (имя)",
    dataIndex: "msUserName",
    key: "msUserName",
    render: (text, record) => <div title={record.fullMsUser ?? ""}>{text}</div>,
    width: 100,
  },
];

function formatCounterpartyInfo(counterparty: any) {
  const info = [
    `Имя: ${counterparty.name || "-"}`,
    `Телефон: ${counterparty.phone || "-"}`,
    `Email: ${counterparty.email || "-"}`,
    `Дата создания: ${counterparty.created_at || "-"}`,
    `Дата обновления: ${counterparty.updated_at || "-"}`,
  ];
  return info.join("\n");
}

function formatMSUserInfo(ms_user: any) {
  const info = [
    `id: ${ms_user.id || "-"}`,
    `ms_id: ${ms_user.ms_id || "-"}`,
    `Логин: ${ms_user.login || "-"}`,
    `Имя: ${ms_user.name || "-"}`,
  ];
  return info.join("\n");
}

function Leads() {
  const dispatch = useAppDispatch();
  const [leadsData, setLeadsData] = useState<LeadsTableData[]>([]);
  const { results, count } = useAppSelector((state) => state.leadsReducer);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 100,
    total: 0,
    position: ["topLeft"],
  });

  const fetchPhoneCalls = useCallback(
    (page = pagination.current) => {
      if (page) {
        trackPromise(dispatch(getLeads(page.toString())));
      }
    },
    [dispatch, pagination]
  );

  useEffect(() => {
    const fetchUsers = () => {
      dispatch(getLeads("1"));
    };

    fetchUsers();
  }, [dispatch]);

  useEffect(() => {
    if (results) {
      const formattedData = results.map((item: any) => ({
        key: item.id ? item.id : "Отсутствует",
        id: item.id ? item.id : "Отсутствует",
        name: item.name ? item.name : "Отсутсвует",
        statusName:
          item.status && item.status.name ? item.status.name : "Отсутствует",
        money: item.money ? item.money : "Отсутствует",
        statusColor: item.status && item.status.color ? item.status.color : "",
        counterpartyPhone:
          item.counterparty.phone && item.counterparty
            ? item.counterparty.phone
            : "Отсутсвует",
        createdAt: item.created_at ? item.created_at : "Отсутсвует",
        msUserName:
          item.ms_user && item.ms_user ? item.ms_user.name : "Отсутствует",
        fullCounterparty: formatCounterpartyInfo(item.counterparty),
        fullMsUser: item.ms_user
          ? formatMSUserInfo(item.ms_user)
          : "Отсутствует",
      }));
      setLeadsData(formattedData);
    }
  }, [results]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      total: count ?? 1,
    }));
  }, [count]);

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    const { current, pageSize } = newPagination;
    if (current !== undefined) {
      fetchPhoneCalls(current);
      setPagination({ ...newPagination, pageSize: pageSize || 100 });
    }
  };

  return (
    <>
      <Header />
      <main>
        <h1>Лиды</h1>
        <Table
          pagination={pagination}
          onChange={handleTableChange}
          dataSource={leadsData}
          columns={columns}
        ></Table>
      </main>
    </>
  );
}

export default Leads;
