import React from "react";
import Header from "../components/Header";

import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Имя менеджера",
    width: 100,
    dataIndex: "managerName",
    key: "managerName",
  },
  {
    title: "Общая сумма",
    width: 100,
    dataIndex: "totalAmount",
    key: "totalAmount",
  },
  {
    title: "Количество лидов",
    dataIndex: "leadsCount",
    key: "leadsCount",
    width: 100,
  },
  {
    title: "Количество упущений промежуток",
    width: 100,
    dataIndex: "omissionsNumber",
    key: "omissionsNumber",
  },
];

function Reports() {
  return (
    <>
      <Header />
      <main>
        <h1>Отчет по менеджерам: Промежуток</h1>
        <Table columns={columns}></Table>
      </main>
    </>
  );
}

export default Reports;
