import React from "react";
import Header from "../components/Header";

function Admin() {
  return (
    <>
      <Header />
      <main>
        <div>Админка</div>
      </main>
    </>
  );
}

export default Admin;
