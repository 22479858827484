import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import * as Loader from "react-loader-spinner";

function LoadingIndicator() {
  const { promiseInProgress } = usePromiseTracker();

  if (promiseInProgress) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  return (
    <>
      {promiseInProgress && (
        <>
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: "9998",
            }}
          />
          <div
            className="spinner"
            style={{
              position: "fixed",
              left: "42vw",
              top: "36vh",
              width: "10vw",
              height: "10vh",
              zIndex: "9999",
            }}
          >
            <Loader.TailSpin color="#445bad" height="100%" width="100%" />
          </div>
        </>
      )}
    </>
  );
}

export default LoadingIndicator;
