import React from "react";
import Header from "../components/Header";

import styled from "styled-components";

import { Card, Space } from "antd";

import { COLORS } from "../utils/constants";
import { useAppSelector } from "../hooks/redux";

function Main() {
  const { username } = useAppSelector((state) => state.authReducer);

  return (
    <>
      <Header />
      <main>
        <h1>Добро пожаловать, {username}</h1>
        <UserDashboard>
          <TasksContainer>
            <TasksHeader>Текущие задачи</TasksHeader>
            <InfoTextItem>
              Задачи открываются и закрываются автоматически
            </InfoTextItem>
            <SectionTask>
              <CardTaskItem title="Таска 1" extra={<a href="#">Подробнее</a>}>
                <p>Task content</p>
                <p>Task content</p>
              </CardTaskItem>
              <CardTaskItem title="Таска 2" extra={<a href="#">Подробнее</a>}>
                <p>Task content</p>
                <p>Task content</p>
              </CardTaskItem>
            </SectionTask>
          </TasksContainer>
          <SectionMetrics>
            <CardItem title="Упущения">
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </CardItem>

            <CardItem title="Лиды">
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </CardItem>

            <CardItem title="Телефонные звонки">
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </CardItem>
          </SectionMetrics>
        </UserDashboard>
      </main>
    </>
  );
}

const UserDashboard = styled.section`
  display: flex;
  gap: 20px;

  @media (max-width: 1450px) {
    flex-direction: column;
  }
`;

const TasksHeader = styled.h2`
  margin-top: 0;
`;

const TasksContainer = styled.section`
  background-color: ${COLORS.grey};
  border-radius: 20px;
  width: 20%;
  padding: 24px;

  @media (max-width: 1450px) {
    width: 100%;
  }
`;

const InfoTextItem = styled.p`
  padding: 0 20px 20px 0;
`;

const SectionTask = styled(Space).attrs({
  direction: "vertical",
  size: 16,
  as: Space,
})`
  width: 100%;
  display: flex;
`;

const CardTaskItem = styled(Card)`
  width: 100%;
`;

const SectionMetrics = styled(Space).attrs({
  direction: "vertical",
  size: 16,
  as: Space,
})`
  width: 80%;

  @media (max-width: 1450px) {
    width: 100%;
  }
`;

const CardItem = styled(Card)`
  width: 100%;
`;

export default Main;
