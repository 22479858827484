import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import Main from "./pages/Main";
import Admin from "./pages/Admin";
import PhoneCalls from "./pages/PhoneCalls";
import Reports from "./pages/Reports";
import Authorization from "./pages/Authorization";
import CallDetails from "./pages/CallDetails";
import PrivateElement from "./components/PrivateElement";
import VirtualNumberSettings from "./pages/VirtualNumberSettings";
import Leads from "./pages/Leads";
import Users from "./pages/Users";

import "./index.css";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { store } from "./store/store";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import LoadingIndicator from "./components/LoadingIndicator";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const persistor = persistStore(store);

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Authorization />,
  },
  {
    path: "/",
    element: (
      <PrivateElement>
        <Main />
      </PrivateElement>
    ),
  },
  {
    path: "/admin",
    element: (
      <PrivateElement>
        <Admin />
      </PrivateElement>
    ),
  },
  {
    path: "/phone-calls",
    element: (
      <PrivateElement>
        <PhoneCalls />
      </PrivateElement>
    ),
  },
  {
    path: "/reports",
    element: (
      <PrivateElement>
        <Reports />
      </PrivateElement>
    ),
  },
  {
    path: "/phone-calls/:id",
    element: (
      <PrivateElement>
        <CallDetails />
      </PrivateElement>
    ),
  },
  {
    path: "/virtual-number-settings",
    element: (
      <PrivateElement>
        <VirtualNumberSettings />
      </PrivateElement>
    ),
  },
  {
    path: "/leads",
    element: (
      <PrivateElement>
        <Leads />
      </PrivateElement>
    ),
  },
  {
    path: "/users",
    element: (
      <PrivateElement>
        <Users />
      </PrivateElement>
    ),
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoadingIndicator></LoadingIndicator>
        <RouterProvider router={router}></RouterProvider>
        <LoadingIndicator></LoadingIndicator>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
